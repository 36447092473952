<template>
  <div>

  
   
    <hb-form label="Acct Number" required>
      <HbTextField
        :disabled="!!form.id && !isEditing"
        v-model="form.value.account_number"
        v-validate.disable="'required'"
        data-vv-name="account_number"
        data-vv-as="Account Number"
        :error="errors.collect('account_number').length > 0"
      />
    </hb-form> 

    <hb-bottom-action-bar :cancel-off="(!!form.id && !isEditing) || !isEditAble" @close="cancel"  v-if="hasPermission('manage_payment_gateways')" :top-border="false">
      <template v-slot:right-actions > 
          <hb-btn :disabled="!hasPermission('delete_payment_gateway')" @click="remove" color="destructive" v-if="form.id && !isEditing && isEditAble">Remove Integration</hb-btn>
          <!--<hb-btn @click="edit" v-if="form.id && !isEditing && isEditAble">Edit</hb-btn>-->
          <hb-btn @click="save" v-if="(!form.id || isEditing) && isEditAble">Save</hb-btn>
      </template>
    </hb-bottom-action-bar>
  </div>
</template>

<script>
import BaseSettings from '../../BasePaymentSettingsForm.vue'
export default {
    name: 'TenantPaymentsACH',
    extends: BaseSettings,
    data() {
        return {

          defaultValues: {
              account_number: ''
          }

        }
    }
}
</script>