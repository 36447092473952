<template>
    <v-expansion-panels v-model="panel">
    <hb-expansion-panel class="mb-2">
        <template v-slot:title>
            Direct Debit
        </template>
        <template v-slot:content>
            <hb-form label="Merchant" required>
                <HbSelect
                    :disabled="!!form.id || !hasPermission('manage_payment_gateways')"
                    v-model="form.name"
                    :items="merchants"
                    v-validate.disable="'required'"
                    item-text="label"
                    item-value="value"
                    data-vv-name="merchant"
                    data-vv-as="Merchant"
                    :error="errors.collect('merchant').length > 0"
                    placeholder="Select merchant"
                />
            </hb-form>

            <fatzebra-debit v-if="form.name && form.name.toLowerCase() === 'fatzebra'" @cancel="cancel" :property_id="property_id" :selected="form" @save="save" @remove="remove" ></fatzebra-debit>

        </template>
    
        </hb-expansion-panel>     

    </v-expansion-panels>  
</template>

<script type="text/babel">

    import FatzebraDebit from './merchants/Fatzebra.vue'

    import { mapGetters } from 'vuex';
    export default {
        name: 'PaymentConfigurationDirectDebit',
        data() {
            return {
                panel: 0,
                
                form: {
                    id: '',
                    name: '',
                    property_id: '', 
                    type: 'directdebit',
                    value: {}
                }
            }
        },
        props: ['connection', 'merchants', 'property_id'], 
        components: {
            FatzebraDebit
        },
        created(){  
            
        },
        computed:{
             ...mapGetters({
                hasPermission: 'authenticationStore/rolePermission'
            }), 
        },
        methods:{
            save(selected){
                let form = Object.assign(this.form, selected);
                this.$emit('save', form);
            }, 
            remove(selected){
                this.$emit('remove', selected);
            },
            cancel(){
                this.form = JSON.parse(JSON.stringify(this.connection));
            },
        },
        watch: {
            connection: { 
                handler: function (val, oldVal) {
                     this.form = JSON.parse(JSON.stringify(val));
            
                },
                deep: true

            }

        }
    }

</script>

