<template>
  <div>

    
    <hb-form label="Organization ID" required>
      <HbTextField
        :disabled="!!form.id && !isEditing"
        v-model="form.value.forteOrganizationId"
        v-validate.disable="'required'"
        data-vv-name="organization_id"
        data-vv-as="Organization ID"
        :error="errors.collect('organization_id').length > 0"
      />
    </hb-form> 

    <hb-form label="Location ID" required>
      <HbTextField
        :disabled="!!form.id && !isEditing"
        v-model="form.value.forteLocationId"
        v-validate.disable="'required'"
        data-vv-name="location_id"
        data-vv-as="Location ID"
        :error="errors.collect('location_id').length > 0"
      />
    </hb-form> 
 
    <hb-form label="Login" required> 
      <HbTextField
        :disabled="!!form.id && !isEditing"
        v-model="form.value.forteLogin"
        v-validate.disable="'required'"
        data-vv-name="login"
        data-vv-as="Login"
        :error="errors.collect('login').length > 0"
      />
    </hb-form> 
    <hb-form label="Password" required>
      <HbTextField
        :disabled="!!form.id && !isEditing"
        v-model="form.value.forteKey"
        v-validate.disable="'required'"
        data-vv-name="password"
        data-vv-as="Password"
        :error="errors.collect('Password').length > 0"
      />
    </hb-form> 
    <hb-bottom-action-bar :cancel-off="(!!form.id && !isEditing) || !isEditAble" @close="cancel" v-if="hasPermission('manage_payment_gateways')" :top-border="false">
      <template v-slot:right-actions > 
          <hb-btn :disabled="!hasPermission('delete_payment_gateway')" @click="remove" color="destructive" v-if="form.id && !isEditing && isEditAble">Remove Integration</hb-btn>
          <hb-btn @click="edit" v-if="form.id && !isEditing && isEditAble">Edit</hb-btn>
          <hb-btn @click="save" v-if="(!form.id || isEditing) && isEditAble">Save</hb-btn>
      </template>
    </hb-bottom-action-bar>
  </div>
</template>

<script>
import BaseSettings from '../../BasePaymentSettingsForm.vue'
export default {
    name: 'ForteACH',
    extends: BaseSettings,
    data() {
        return {    
          defaultValues: {
              forteOrganizationId: '',
              forteLocationId: '',
              forteLogin: '',
              forteKey: ''
          }
        }
    }
}
</script>
