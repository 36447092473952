<template>
    <v-expansion-panels v-model="panel">
        <hb-expansion-panel class="mb-2">
            <template v-slot:title>
                Credit Cards
            </template>
           
            <template v-slot:content>
                <hb-form label="Merchant" required>
                    <HbSelect
                        :disabled="!!form.id || !hasPermission('manage_payment_gateways')"
                        v-model="form.name"
                        :items="merchants"
                        v-validate.disable="'required'"
                        item-text="label"
                        item-value="value"
                        data-vv-name="merchant"
                        data-vv-as="Merchant"
                        :error="errors.collect('merchant').length > 0"
                        placeholder="Select merchant"
                    />
                </hb-form>
                <authorizenet v-if="form.name && form.name.toLowerCase() === 'authorizenet'" @cancel="cancel"  :property_id="property_id" :selected="form" @save="save" @remove="remove" ></authorizenet>
                <fatzebra-card v-if="form.name && form.name.toLowerCase() === 'fatzebra'" @cancel="cancel"  :property_id="property_id" :selected="form" @save="save" @remove="remove" ></fatzebra-card>
                <tsys-card v-if="form.name && form.name.toLowerCase() === 'tsys'" :property_id="property_id" @cancel="cancel" :selected="form" @save="save" @remove="remove" ></tsys-card> 
                <tenant-payments-card v-if="form.name && form.name.toLowerCase() === 'tenant_payments'" @cancel="cancel" :property_id="property_id" :selected="form" @save="save" @remove="remove" ></tenant-payments-card>
            </template>
        </hb-expansion-panel>
    </v-expansion-panels>
</template>

<script type="text/babel">
    
    import Authorizenet from './merchants/Authnet.vue'
    import FatzebraCard from './merchants/Fatzebra.vue'
    import TsysCard from './merchants/Tsys.vue'
    import TenantPaymentsCard from './merchants/TenantPayments.vue'
    import { mapGetters } from 'vuex';
    export default {
        name: 'PaymentConfigurationCard',
        data() {
            return {
                panel: 0,   
                form: {
                    id: '',
                    name: '',
                    property_id: '', 
                    type: 'card',
                    value: {},
                    Devices:[]
                } 
            }
        },
        props: ['connection', 'merchants', 'property_id'], 
        created(){  
            this.form.type == 'card';
        },
        computed:{
             ...mapGetters({
                hasPermission: 'authenticationStore/rolePermission'
            }), 
        },
        components: {
            Authorizenet,
            FatzebraCard,
            TsysCard,
            TenantPaymentsCard
        },
        methods:{
            save(selected){
                let form = Object.assign(this.form, selected);
                this.$emit('save', form);
            }, 
            remove(selected){
                this.$emit('remove', selected);
            },
            cancel(){
                this.form = JSON.parse(JSON.stringify(this.connection));
            },
        },
        watch: {
            connection: { 
                handler: function (val, oldVal) {
                     this.form = JSON.parse(JSON.stringify(val));
            
                },
                deep: true

            }

        }
    }

</script>