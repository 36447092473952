<template>
  <div>

    <hb-form label="API Key" required>
      <HbTextField
        :disabled="!!form.id"
        v-model="form.value.api_key"
        v-validate.disable="'required'"
        data-vv-name="api_key"
        data-vv-as="Api Key"
        :error="errors.collect('api_key').length > 0"
      />
    </hb-form> 

    <hb-form label="Acct Number" required>
      <HbTextField
        :disabled="!!form.id"
        v-model="form.value.account_number"
        v-validate.disable="'required'"
        data-vv-name="account_number"
        data-vv-as="Account Number"
        :error="errors.collect('account_number').length > 0"
      />
    </hb-form> 



    <hb-form label="Device ID" required>
      <HbTextField
        :disabled="!!form.id"
        v-model="form.value.deviceId"
        v-validate.disable="'required'"
        data-vv-name="deviceId"
        data-vv-as="Device ID"
        :error="errors.collect('deviceId').length > 0"
      />
    </hb-form> 

      <div style="display: flex" >
          <v-col cols="8" class="hb-default-font-size font-weight-medium">Swiper Terminals</v-col>
          <v-col cols="4" class="align-right">
            <a v-if="!form.id || isEditing" @click="addDevice()">+ Add New Device</a> 
            
            </v-col>
      </div>

      <v-divider></v-divider>
      <v-expansion-panels flat v-model="devicePanel" multiple class="hb-expansion-panel ma-0 pa-0">

        <!-- <hb-expansion-panel class="ma-0 pa-0" v-for="(d, i) in form.value.Devices" :key="i"> -->
        <v-expansion-panel class="ma-0 pa-0" v-for="(d, i) in form.Devices" :key="i">
          <v-expansion-panel-header class="hb-table-label py-0 my-0 px-4 hb-subpanel-header">
              <v-col cols="8" class="pa-0 ma-0">
                  <span class="hb-default-font-size font-weight-medium">Device {{i+1}}</span>
              </v-col>
               <template v-slot:actions >
                  <div class="d-flex align-center mr-3">
                      <hb-link v-if="!form.id || isEditing" @click="removeDevice(i)" class="hb-default-font-size">Remove</hb-link>
                      <template v-else>
					  
                      <hb-link small  @click="testDevice(i)" class="hb-default-font-size">Test Credit Card Reader</hb-link>
                      <loader v-show="testDeviceLoading === i" color="#00b2ce" size="20px" class="inline-loader"></loader>
                      </template>
                  </div>
                  <div class="d-flex align-center">
                    <v-icon>$expand</v-icon>
                  </div>
              </template> 
          </v-expansion-panel-header>
          <v-divider></v-divider>
          <v-expansion-panel-content class="pa-0 ma-0" elevation="0">
            <hb-form label="Device Name">
              <HbTextField
                :disabled="!!form.id && !isEditing"
                v-model="form.Devices[i].name"
                v-validate="'required|max:100'"
                data-vv-name="device_name"
                data-vv-as="Device Name"
                :error="errors.collect('device_name').length > 0"
                placeholder="Enter Device Name"
              />
            </hb-form> 


            <hb-form label="Device IP Address">
              <div v-show="!!form.id && !isEditing" class="checkmark" >
              <span class="icon"></span><span v-if ="form.Devices[i].lan==1"> Local Network </span><span v-if ="form.Devices[i].lan==0"> Internet </span>
                
              </div>
              <v-row v-show ="(form.id && isEditing) || !form.id ">

              <hb-radio-group   class="mt-1" v-model="form.Devices[i].lan" :mandatory="true" row>
                <div disable="true">Select the network communication type then enter the IP address.</div>
                <hb-radio value="0">
                  <template v-slot:label>
                Internet
              </template>
                </hb-radio>
                  <hb-radio value="1">
                  <template v-slot:label>
                Local Network
        </template>
                </hb-radio>
                
              </hb-radio-group>
            </v-row>

              <HbTextField
                :disabled="!!form.id && !isEditing"
                v-model="form.Devices[i].ip"
                v-validate="{ required: true, regex: /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/ ||  /^((([0-9a-fA-F]){1,4})\\:){7}([0-9a-fA-F]){1,4}$/}"
                data-vv-name="ip"
                data-vv-as="Device IP"
                :error="errors.collect('ip').length > 0"
                placeholder="Enter Device IP Address"
              />
              
            </hb-form> 



            <hb-form label="Device Port">
                <HbTextField
                  :disabled="!!form.id && !isEditing"
                  v-model="form.Devices[i].port"
                  v-validate="{ required: true, regex: /^(?:[0-9]|[1-9]\d{1,3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-6])$/}"
                  data-vv-name="device_port"
                  data-vv-as="Device Port"
                  :error="errors.collect('device_port').length > 0"
                  placeholder="Enter Device Port"
                />
              </hb-form> 


               <hb-form label="Device ID">
                  <HbTextField
                    :disabled="!!form.id && !isEditing"
                    v-model="form.Devices[i].identifier"
                    v-validate="'required|max:100'"
                    data-vv-name="identifier"
                    data-vv-as="Device ID"
                    :error="errors.collect('identifier').length > 0"
                    placeholder="Enter Device ID"
                  />
              </hb-form> 
          </v-expansion-panel-content> 
        </v-expansion-panel>
      </v-expansion-panels>
 

    


    <hb-bottom-action-bar :cancel-off="(!!form.id && !isEditing) || !isEditAble" @close="cancel"  v-if="hasPermission('manage_payment_gateways')" :top-border="false">
      <template v-slot:right-actions > 
          <hb-btn :disabled="!hasPermission('delete_payment_gateway')" @click="remove" color="destructive" v-if="form.id && !isEditing && isEditAble">Remove Integration</hb-btn>
          <hb-btn @click="edit" v-if="form.id && !isEditing && isEditAble">Edit</hb-btn>
          <hb-btn @click="save" v-if="(!form.id || isEditing) && isEditAble">Save</hb-btn>
      </template>
    </hb-bottom-action-bar>
  </div> 
</template>

<script>

import BaseSettings from '../../BasePaymentSettingsForm.vue' ;

export default {
  name: 'TenantPaymentsCard',
  extends: BaseSettings,
  data() {
    return {
      devicePanel: [],
      defaultValues: {
        testing_device: null,
        api_key: '',
        // device_id: '',
        
        account_number: ''

      }
    }
  }
  
}
</script>

