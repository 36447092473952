<template>
<div>
    
    <hb-modal
        size="large"
        title="Tenant Payments Application"
        v-model="showForm"  
        @close="$emit('close')" 
        show-help-link
    >
    <template v-slot:subheader>
        
        Fill out the information below to complete your application for Tenant Payments.    
    </template>
    <template v-slot:content >
        <div class="pa-4" style="background: #F9FAFB; height: 502.5px; overflow: auto ">
            <v-card elevation="1">
                <hb-form label="Registration Code" required >
                    <HbTextField 
                        autofocus
                        v-model="form.general.registration_code"
                        v-validate.disable="'required'"
                        data-vv-name="registration_code"
                        data-vv-as="Registration Code"
                         placeholder="Code"
                        :error="errors.collect('registration_code').length > 0"
                    />
                </hb-form> 
            </v-card>

            <v-expansion-panels v-model="form_panels" multiple>

                <!--  Account Owner -->

                <hb-expansion-panel class="mb-2">
                    <template v-slot:title>
                        Account Owner
                    </template>
                    <template v-slot:content >
                        
                        <hb-form label="Full Name" required multiple>
                            <v-row>
                                <v-col class="py-0"> 
                                    <HbTextField
                                        v-model="form.account_owner.first_name"
                                        v-validate.disable="'required'"
                                        placeholder="First"
                                        data-vv-name="account_owner_first_name"
                                        data-vv-as="First Name"
                                        :error="errors.collect('account_owner_first_name').length > 0"
                                    />
                                </v-col>
                                <v-col class="py-0">
                                    <HbTextField
                                        v-model="form.account_owner.last_name"
                                        v-validate.disable="'required'"
                                        placeholder="Last"
                                        data-vv-name="account_owner_last_name"
                                        data-vv-as="Last Name"
                                        :error="errors.collect('account_owner_last_name').length > 0"
                                    />
                                </v-col>
                            </v-row>
                        </hb-form> 
                        

                            <hb-form label="Social Security #" required >
                            <HbTextField
                                
                                v-model="form.account_owner.ssn"
                                v-validate.disable="'required|length:11'"
                                data-vv-name="account_owner_ssn" 
                                data-vv-as="SSN"
                                v-mask="'###-##-####'"
                                placeholder="Social Security #"
                                :error="errors.collect('account_owner_ssn').length > 0"
                            />
                        </hb-form> 

                        <hb-form label="Date of Birth" required >
                            <HbTextField
                                
                                v-model="form.account_owner.dob"
                                v-validate.disable="'required|date_format:mm/dd/yyyy'"
                                data-vv-name="account_owner_dob"
                                v-mask="'##/##/####'"
                                data-vv-as="Date Of Birth"
                                placeholder="MM/DD/YYYY"
                                :error="errors.collect('account_owner_dob').length > 0"
                            />
                        </hb-form> 

                        


                        <hb-form label="Personal Address" required multiple >
                            <v-row>
                                <v-col class="py-0"> 
                                    <HbTextField
                                        v-model="form.account_owner.address"
                                        v-validate.disable="{ required: true, customAddressValidation: true }"
                                        placeholder="Street"
                                        data-vv-name="account_owner_address"
                                        data-vv-as="Street"
                                        :error="errors.collect('account_owner_address').length > 0"
                                    />
                                </v-col>
                            </v-row> 
                            <v-row>
                                <v-col class="py-0">
                                    <HbTextField
                                        v-model="form.account_owner.address2"
                                        v-validate.disable="{ customAddressValidation: true }"
                                        placeholder="Suite / Apt..."
                                        data-vv-name="account_owner_address2"
                                        data-vv-as="Suite / Apt"
                                        :error="errors.collect('account_owner_address2').length > 0"
                                    />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col class="py-0">
                                    <HbTextField
                                        v-model="form.account_owner.zip"
                                        v-validate.disable="'required|max:5'"
                                        placeholder="Zip"
                                        data-vv-name="account_owner_zip"
                                        v-mask="'#####'"
                                        data-vv-as="Zip"
                                        :error="errors.collect('account_owner_zip').length > 0"
                                    />
                                </v-col>
                                <v-col class="py-0">

                                    <vuetify-statepicker
                                        id="state"
                                        name="account_owner_state"
                                        :single_line="true"
                                        v-validate.disable="'required'"
                                        v-model="form.account_owner.state"
                                        data-vv-as="State"
                                        :clearable="true"
                                        @click:clear="form.state = ''"
                                         :error="errors.collect('account_owner_state').length > 0"
                                    ></vuetify-statepicker>
                                </v-col>

                                <v-col class="py-0">
                                    <HbTextField
                                        v-model="form.account_owner.city"
                                        v-validate.disable="'required'"
                                        placeholder="City"
                                        data-vv-name="account_owner_city"
                                        data-vv-as="City"
                                        :error="errors.collect('account_owner_city').length > 0"
                                    />
                                </v-col>
                            </v-row>

                                <v-row>
                                <v-col class="py-0"> 
                                    <HbAutocomplete
                                        v-model="form.account_owner.country"
                                        :items="['USA','Canada']"
                                        v-validate.disable="'required'"
                                        data-vv-name="account_owner_country"
                                        data-vv-as="Country"
                                        :error="errors.collect('account_owner_country').length > 0"
                                        placeholder="Country"
                                    />
                                </v-col>
                            </v-row>

                        </hb-form> 


                        <hb-form label="Day Phone" required multiple>
                            
                            <v-row>
                                <v-col cols="2" class="py-0"> 
                                    <HbSelect
                                        v-model="form.account_owner.day_phone_country_code"
                                        :items="phoneCountryCodes"
                                        v-validate.disable="'required'"
                                        data-vv-name="account_owner_day_phone_country_code"
                                        data-vv-as="Day Phone Code"
                                        :error="errors.collect('account_owner_day_phone_country_code').length > 0"
                                        placeholder="Select"
                                        :clearable="false"
                                    />  
                                </v-col>
                                <v-col class="py-0">
                                    <HbTextField
                                        v-model="form.account_owner.day_phone_number"
                                        v-validate.disable="'required|length:14'"
                                        v-mask="'(###) ###-####'"
                                        data-vv-name="account_owner_day_phone_number"
                                        data-vv-as="Day Phone Number"
                                        placeholder="Number"
                                        :error="errors.collect('account_owner_day_phone_number').length > 0"
                                    />
                                </v-col>
                            </v-row>

                        </hb-form> 
                         <hb-form label="Evening Phone" required multiple>
                            
                            <v-row>
                                <v-col cols="2" class="py-0"> 
                                    <HbSelect
                                        v-model="form.account_owner.evening_phone_country_code"
                                        :items="phoneCountryCodes"
                                        v-validate.disable="'required'"
                                        data-vv-name="account_owner_evening_phone_country_code"
                                        data-vv-as="Evening Phone Code"
                                        :error="errors.collect('account_owner_evening_phone_country_code').length > 0"
                                        placeholder="Select"
                                        :clearable="false"
                                    />  
                                </v-col>
                                <v-col class="py-0">
                                    <HbTextField
                                        v-model="form.account_owner.evening_phone_number"
                                        v-validate.disable="'required|length:14'"
                                        v-mask="'(###) ###-####'"
                                        data-vv-name="account_owner_evening_phone_number"
                                        data-vv-as="EveningPhone Number"
                                        placeholder="Number"
                                        :error="errors.collect('account_owner_evening_phone_number').length > 0"
                                    />
                                </v-col>
                            </v-row>

                        </hb-form> 
                    </template>
                </hb-expansion-panel>
                
                <!--  END Account Owner -->
                <!--  Business Information -->
                            
                <hb-expansion-panel class="mb-2">
                    <template v-slot:title>
                        Business Information
                    </template>
                    <template v-slot:content class="pa-4">
                        
                        <hb-form label="Business Legal Name" required >
                            <HbTextField
                                
                                v-model="form.business_information.legal_name"
                                v-validate.disable="'required'"
                                data-vv-name="business_info_legal_name"
                                data-vv-as="Business Legal Name"
                                placeholder="Legal Name"
                                :error="errors.collect('business_info_legal_name').length > 0"
                            />
                        </hb-form> 

                        <hb-form label="DBA Name" required >
                            <HbTextField
                                
                                v-model="form.business_information.dba"
                                v-validate.disable="'required'"
                                data-vv-name="business_info_dba"
                                data-vv-as="DBA Name"
                                placeholder="DBA Name"
                                :error="errors.collect('business_info_dba').length > 0"
                            />
                        </hb-form> 

                        <hb-form label="Federal Tax ID Num (EIN)" required >
                            <HbTextField
                                
                                v-model="form.business_information.ein"
                                v-validate.disable="'required|length:10'"
                                data-vv-name="business_info_ein"
                                data-vv-as="EIN"
                                placeholder="EIN"
                                v-mask="'##-#######'"
                                :error="errors.collect('business_info_ein').length > 0"
                            />
                        </hb-form> 





                        <hb-form label="Business Address" required multiple >
                            <v-row >
                                <v-col class="py-0"> 
                                    <HbTextField
                                        v-model="form.business_information.address"
                                        v-validate.disable="{ required: true, customAddressValidation: true }"
                                        placeholder="Street"
                                        data-vv-name="business_info_address"
                                        data-vv-as="Street"
                                        :error="errors.collect('business_info_address').length > 0"
                                    />
                                </v-col>
                            </v-row> 
                            <v-row >
                                <v-col class="py-0">
                                    <HbTextField
                                        v-model="form.business_information.address2"
                                        v-validate.disable="{ customAddressValidation: true }"
                                        placeholder="Suite / Apt..."
                                        data-vv-name="business_info_address2"
                                        data-vv-as="Suite / Apt"
                                        :error="errors.collect('business_info_address2').length > 0"
                                    />
                                </v-col>
                            </v-row>

                            <v-row >
                                <v-col class="py-0">
                                    <HbTextField
                                        v-model="form.business_information.zip"
                                        v-validate.disable="'required|max:5'"
                                        placeholder="Zip"
                                        data-vv-name="business_info_zip"
                                        v-mask="'#####'"
                                        data-vv-as="Zip"
                                        :error="errors.collect('business_info_zip').length > 0"
                                    />
                                </v-col>
                                <v-col class="py-0">

                                    <vuetify-statepicker
                                        id="state"
                                        autocomplete="off"
                                        name="business_info_state"
                                        :single_line="true"
                                        data-vv-as="State"
                                        v-validate.disable="'required'"
                                        v-model="form.business_information.state"
                                        :clearable="true"
                                        @click:clear="form.state = ''"
                                        :error="errors.collect('business_info_state').length > 0"
                                    ></vuetify-statepicker>
                                </v-col>

                                <v-col class="py-0">
                                    <HbTextField
                                        v-model="form.business_information.city"
                                        v-validate.disable="'required'"
                                        placeholder="City"
                                        data-vv-name="business_info_city"
                                        data-vv-as="City"
                                        :error="errors.collect('business_info_city').length > 0"
                                    />
                                </v-col>
                            </v-row>

                                <v-row >
                                <v-col class="py-0"> 
                                    <HbAutocomplete
                                        autocomplete="off"
                                        v-model="form.business_information.country"
                                        :items="['USA','Canada']"
                                        v-validate.disable="'required'"
                                        data-vv-name="business_info_country"
                                        data-vv-as="Country"
                                        :error="errors.collect('business_info_country').length > 0"
                                        placeholder="Country"
                                    />
                                </v-col>
                            </v-row>

                           
                        </hb-form> 

                        <hb-form label="Property Time Zone" required >
                            <HbSelect
                                :items="formattedTimeZones"
                                item-text="label"
                                item-value="value"
                                v-model="form.business_information.property_timezone"
                                v-validate.disable="'required'"
                                data-vv-name="property_timezone"
                                data-vv-as="Property Timezone"
                                placeholder="Property Timezone"
                                :error="errors.collect('property_timezone').length > 0"
                                :clearable="false"
                            />
                        </hb-form> 

                        <hb-form label="Primary Business Email" required >
                             <template v-slot:tooltipBody>This email address must be unique and not registered to any other Tenant Payments account</template>
                            <HbTextField
                                
                                v-model="form.account_owner.email_address"
                                v-validate.disable="'required|email|emailAlphaNumeric'"
                                data-vv-name="account_owner_email_address"
                                data-vv-as="Primary Business Email"
                                placeholder="Email"
                                :error="errors.collect('account_owner_email_address').length > 0"
                            />
                            
                        </hb-form> 

                         <hb-form label="Notification Email" required >
                             <template v-slot:tooltipBody>This email address will be used for all communications and notifications related to your Tenant Payments account.</template>
                            <HbTextField
                                
                                v-model="form.business_information.notification_email"
                                v-validate.disable="'required|email'"
                                data-vv-name="business_info_notification_email"
                                data-vv-as="Notification Email"
                                placeholder="Notification Email"
                                :error="errors.collect('business_info_notification_email').length > 0"
                            />
                           
                        </hb-form> 

                         <hb-form label="Business Website URL" required >
                            <HbTextField
                                
                                v-model="form.business_information.website"
                                v-validate.disable="'required'"
                                data-vv-name="business_info_website"
                                data-vv-as="Business Website URL"
                                placeholder="Website URL"
                                :error="errors.collect('business_info_website').length > 0"
                            />
                        </hb-form> 



                    </template>
                </hb-expansion-panel>


                 <!-- END Business Information -->
                <!-- Beneficiaries -->


                <hb-expansion-panel class="mb-2">
                    <template v-slot:title>
                        Beneficial Owner(s)
                    </template>
                    <template v-slot:description>
                        Add the information for each beneficial owner that owns 25% or more. 
                    </template> 
                    <template v-slot:content >
                        
                        <v-row class="px-4">
                            <v-col class="hb-text-night-light">Add the information for each beneficial owner that owns 25% or more. </v-col>
                        </v-row> 
                        <v-divider></v-divider>
                        
                        <v-expansion-panels flat v-model="beneficiariesPanel" multiple class="hb-expansion-panel ma-0 pa-0">
                            <v-expansion-panel class="ma-0 pa-0" v-for="(b,i) in form.beneficiaries" :key="i">
                                <v-expansion-panel-header class="hb-table-label py-0 my-0 px-4 hb-subpanel-header">
                                    <v-col cols="8" class="pa-0 ma-0">
                                        <span class="hb-default-font-size font-weight-medium">Beneficial Owner {{i+1}}</span>
                                    </v-col>
                                    <template v-slot:actions >
                                        <div class="d-flex align-center mr-3" v-if="i > 0">
                                            <hb-link @click="removeBeneficialOwner(i)" class="hb-default-font-size">Remove</hb-link>
                                        </div>
                                        <div class="d-flex align-center">
                                            <v-icon>$expand</v-icon>
                                        </div>
                                    </template> 
                                </v-expansion-panel-header>
                                <v-divider></v-divider>
                                <v-expansion-panel-content class="pa-0 ma-0" elevation="0">
                                
                                <hb-form label="Full Name" required multiple>
                                <v-row >
                                    <v-col class="py-0"> 
                                        <HbTextField
                                            v-model="form.beneficiaries[i].first_name"
                                            v-validate.disable="'required'"
                                            placeholder="First"
                                            :data-vv-name="`beneficial_${i}_first_name`"
                                            data-vv-as="First Name"
                                            :error="errors.collect(`beneficial_${i}_first_name`).length > 0"
                                        />
                                    </v-col>
                                    <v-col class="py-0">
                                        <HbTextField
                                            v-model="form.beneficiaries[i].last_name"
                                            v-validate.disable="'required'"
                                            placeholder="Last"
                                            :data-vv-name="`beneficial_${i}_last_name`"
                                            data-vv-as="Last Name"
                                            :error="errors.collect(`beneficial_${i}_last_name`).length > 0"
                                        />
                                    </v-col>
                                </v-row>
                            </hb-form> 
                        

                            <hb-form label="Social Security #" required >
                                <HbTextField
                                    
                                    v-model="form.beneficiaries[i].ssn"
                                    v-validate.disable="'required|length:11'"
                                    :data-vv-name="`beneficial_${i}_ssn`" 
                                    data-vv-as="SSN"
                                    v-mask="'###-##-####'"
                                    placeholder="Social Security #"
                                    :error="errors.collect(`beneficial_${i}_ssn`).length > 0"
                                />
                            </hb-form> 

                            <hb-form label="Date of Birth" required  >
                                <HbTextField
                                    
                                    v-model="form.beneficiaries[i].dob"
                                    v-validate.disable="'required|date_format:mm/dd/yyyy'"
                                     :data-vv-name="`beneficial_${i}_dob`" 
                                        v-mask="'##/##/####'"
                                    data-vv-as="Date Of Birth"
                                    placeholder="MM/DD/YYYY"
                                    :error="errors.collect(`beneficial_${i}_dob`).length > 0"
                                />
                            </hb-form> 

                            <hb-form label="Email Address" required  >
                                <HbTextField
                                    
                                    v-model="form.beneficiaries[i].email_address"
                                    v-validate.disable="'required|email'"
                                    :data-vv-name="`beneficial_${i}_email_address`"
                                    data-vv-as="Email Address"
                                    placeholder="Email"
                                    :error="errors.collect(`beneficial_${i}_email_address`).length > 0"
                                />
                            </hb-form> 


                            <hb-form label="Beneficial Owner Address" multiple required >
                                <v-row >
                                    <v-col class="py-0"> 
                                        <HbTextField
                                            v-model="form.beneficiaries[i].address"
                                            v-validate.disable="{ required: true, customAddressValidation: true }"
                                            placeholder="Street"
                                            :data-vv-name="`beneficial_${i}_address`"
                                            data-vv-as="Street"
                                            :error="errors.collect(`beneficial_${i}_address`).length > 0"
                                        />
                                    </v-col>
                                </v-row> 
                                <v-row >
                                    <v-col class="py-0">
                                        <HbTextField
                                            v-model="form.beneficiaries[i].address2"
                                            v-validate.disable="{ customAddressValidation: true }"
                                            placeholder="Suite / Apt..."
                                            :data-vv-name="`beneficial_${i}_address`"
                                            data-vv-as="Suite / Apt"
                                            :error="errors.collect(`beneficial_${i}_address2`).length > 0"
                                        />
                                    </v-col>
                                </v-row>

                                <v-row >
                                    <v-col class="py-0">
                                        <HbTextField
                                            v-model="form.beneficiaries[i].zip"
                                            v-validate.disable="'required|length:5'"
                                            placeholder="Zip"
                                            :data-vv-name="`beneficial_${i}_zip`"
                                            v-mask="'#####'"
                                            data-vv-as="Zip"
                                            :error="errors.collect(`beneficial_${i}_zip`).length > 0"
                                        />
                                    </v-col>
                                    <v-col class="py-0">

                                        <vuetify-statepicker
                                            v-model="form.beneficiaries[i].state"
                                            id="state"
                                            :name="`beneficial_${i}_state`"
                                            :single_line="true"
                                            :clearable="true"
                                             data-vv-as="State"
                                             v-validate.disable="'required'"
                                            @click:clear="form.state = ''"
                                            :error="errors.collect(`beneficial_${i}_state`).length > 0"
                                        ></vuetify-statepicker>
                                    </v-col>

                                    <v-col class="py-0">
                                        <HbTextField
                                            v-model="form.beneficiaries[i].city"
                                            placeholder="City"
                                            :data-vv-name="`beneficial_${i}_city`"
                                            data-vv-as="City"
                                            v-validate.disable="'required'"
                                            :error="errors.collect(`beneficial_${i}_city`).length > 0"
                                        />
                                    </v-col>
                                </v-row>

                                <v-row >
                                    <v-col class="py-0"> 
                                        <HbAutocomplete
                                            v-model="form.beneficiaries[i].country"
                                            :items="['USA','Canada']"
                                            :data-vv-name="`beneficial_${i}_country`"
                                            data-vv-as="Country"
                                            v-validate.disable="'required'"
                                            :error="errors.collect(`beneficial_${i}_country`).length > 0"
                                            placeholder="Country"
                                        />
                                    </v-col>
                                </v-row>
                            </hb-form> 

                            <hb-form label="Title" required>
                                <HbTextField
                                    v-model="form.beneficiaries[i].title"
                                    :data-vv-name="`beneficial_${i}_title`"
                                    data-vv-as="Title"
                                    v-validate.disable="'required'"
                                    :error="errors.collect(`beneficial_${i}_title`).length > 0"
                                    placeholder="Title"
                                />
                            </hb-form>
                            <hb-form label="Ownership Percentage" required >
                                <HbTextField
                                    v-model="form.beneficiaries[i].ownership"
                                    v-validate.disable="'required|numeric|min_value:0|max_value:100'"
                                    :data-vv-name="`beneficial_${i}_ownership`"
                                    data-vv-as="Ownership Percentage"
                                    :error="errors.collect(`beneficial_${i}_ownership`).length > 0"
                                    placeholder="Amount"
                                />
                            </hb-form>
                                
                    
                            </v-expansion-panel-content>

                        </v-expansion-panel>
                        </v-expansion-panels>
                        <v-card>
                            <v-container>
                            <v-row no-gutters>
                                <v-col class="text-right">
                                    <a @click="addBeneficialOwner()">+ Add Additional Beneficial Owner</a> 
                                </v-col>
                            </v-row>
                            </v-container>
                        </v-card>
                    </template>
                </hb-expansion-panel>
                    

                <!-- END Beneficiaries -->
                <!-- Transaction Information -->

                <hb-expansion-panel class="mb-2">
                    <template v-slot:title>
                       Transaction Information
                    </template>
                    <template v-slot:content>
                        
                        <hb-form label="Expected Monthly Volume" required >
                            <HbTextField
                                prepend-icon="mdi-currency-usd"
                                type="number"
                                v-model="form.transaction_info.monthly_volume"
                                v-validate.disable="'required|integer|min_value:0'"
                                data-vv-name="monthly_volume"
                                data-vv-as="Expected Monthly Volume"
                                placeholder="Volume"
                                :error="errors.collect('monthly_volume').length > 0"
                            />
                        </hb-form> 

                        <hb-form label="Average Ticket" required >
                            <HbTextField
                                prepend-icon="mdi-currency-usd"
                                type="number"
                                v-model="form.transaction_info.average_ticket"
                                v-validate.disable="'required|integer|min_value:0'"
                                data-vv-name="average_ticket"
                                data-vv-as="Average Ticket"
                                placeholder="Average"
                                :error="errors.collect('average_ticket').length > 0"
                            />
                        </hb-form> 


                        <hb-form label="Highest Ticket" required >
                            <HbTextField
                                prepend-icon="mdi-currency-usd"
                                type="number"
                                v-model="form.transaction_info.highest_ticket"
                                v-validate.disable="'required|integer|min_value:0'"
                                data-vv-name="highest_ticket"
                                data-vv-as="Highest Ticket"
                                placeholder="Amount"
                                :error="errors.collect('highest_ticket').length > 0"
                            />
                        </hb-form> 

                    </template>
                </hb-expansion-panel>

                <!-- END Transaction Information -->
                <!-- Banking Information -->

                <hb-expansion-panel class="mb-2">
                    <template v-slot:title>
                       Banking Information
                    </template>
                    <template v-slot:content>
                        
                        <hb-form label="Name on Account" required multiple>
                            <v-row >
                                <v-col class="py-0"> 
                                    <HbTextField
                                        v-model="form.banking_info.first_name"
                                        v-validate.disable="'required'"
                                        placeholder="First"
                                        data-vv-name="bank_first_name"
                                        data-vv-as="Bank Account First Name"
                                        :error="errors.collect('bank_first_name').length > 0"
                                    />
                                </v-col>
                                <v-col class="py-0">
                                    <HbTextField
                                        v-model="form.banking_info.last_name"
                                        v-validate.disable="'required'"
                                        placeholder="Last"
                                        data-vv-name="bank_last_name"
                                        data-vv-as="Bank Account Last Name"
                                        :error="errors.collect('bank_last_name').length > 0"
                                    />
                                </v-col>
                            </v-row>
                        </hb-form> 


                        <hb-form label="Account Ownership Type" required >
                            <HbSelect
                                v-model="form.banking_info.ownership_type"
                                :items="ownershipTypes"
                                v-validate.disable="'required'"
                                data-vv-name="ownership_type"
                                data-vv-as="Account Ownership Type"
                                :error="errors.collect('ownership_type').length > 0"
                                placeholder="Select"
                                :clearable="false"
                            />  
                        </hb-form>


                        <hb-form label="Account Type" required >

                            <HbSelect
                                v-model="form.banking_info.account_type"
                                :items="accountTypes"
                                v-validate.disable="'required'"
                                data-vv-name="account_type"
                                data-vv-as="Account Type"
                                :error="errors.collect('account_type').length > 0"
                                placeholder="Select"
                                :clearable="false"
                            />  
                        </hb-form>


                        <hb-form label="Bank Name" required >
                            <HbTextField
                                v-model="form.banking_info.bank_name"
                                v-validate.disable="'required'"
                                placeholder="Bank Name"
                                data-vv-name="bank_name"
                                data-vv-as="Bank Name"
                                :error="errors.collect('bank_name').length > 0"
                            />
                        </hb-form>

                        <hb-form label="Bank Routing Number" required >
                            <HbTextField
                                v-model="form.banking_info.routing_number"
                                type="number"
                                v-validate.disable="'required|integer|min:5|max:9'"
                                placeholder="Routing Number"
                                data-vv-name="routing_number"
                                data-vv-as="Routing Number"
                                :error="errors.collect('routing_number').length > 0"
                            />
                        </hb-form>

                        <hb-form label="Bank Account Number" required >
                            <HbTextField
                                v-model="form.banking_info.account_number"
                                v-validate.disable="'required|min:5'"
                                placeholder="Account Number"
                                data-vv-name="account_number"
                                data-vv-as="Account Number"
                                :error="errors.collect('account_number').length > 0"
                            />
                        </hb-form>

                        <hb-form label="Country Code" required >
                            <HbSelect
                                autocomplete="off"
                                v-model="form.banking_info.country_code"
                                :items="countryCodes"
                                v-validate.disable="'required'"
                                data-vv-name="country_code"
                                data-vv-as="Country Code"
                                :error="errors.collect('country_code').length > 0"
                                placeholder="Select"
                                :clearable="false"
                            />  
                        </hb-form>


                    </template>
                </hb-expansion-panel>


                <!-- END Banking Information -->
                <!-- Equipment -->

                <hb-expansion-panel class="mb-2" expand-icon-off readonly hidden>
                    <template v-slot:title @click="toggleEquipmentPurchase">
                        Do You Want To Purchase Equipment?
                    </template>

                    <template v-slot:actions >
                        <HbSwitch
                            right
                            v-model="form.equipment.will_purchase_equipment"
                            :label="!!form.equipment.will_purchase_equipment ? 'Yes': 'No'"
                        >
                        </HbSwitch>
                    </template>
                    <template v-slot:content>
                       <div v-if="!!form.equipment.will_purchase_equipment">
                         <hb-form label="Device Name" required >
                            <HbSelect
                                :items="devices" 
                                v-model="form.equipment.device_name"
                                v-validate.disable="'required'"
                                item-text="label"
                                item-value="value"
                                :clearable="false"
                                data-vv-name="device_name"
                                data-vv-as="Device Name"
                                placeholder="Device Name"
                                :error="errors.collect('device_name').length > 0"
                            />
                        </hb-form> 
                        
                        



                        <hb-form label="Shipping Address" required >
                            <hb-radio-group v-model="form.equipment.shipping_location_type">
                                
                                    <v-row no-gutters>
                                        <v-col >
                                            <HbRadio label="Business" value="business" />
                                        </v-col>
                                        <v-col>
                                            <HbRadio label="Other" value="other" />
                                        </v-col>
                                        <v-spacer />
                                    </v-row>
                            </hb-radio-group>
                            <div v-show="form.equipment.shipping_location_type === 'other'">
                                <v-row>
                                    <v-col class="py-0"> 
                                        <HbTextField
                                            v-model="form.equipment.address"
                                            v-validate.disable="{ required: true, customAddressValidation: true }"
                                            placeholder="Street"
                                            data-vv-name="business_info_address"
                                            data-vv-as="Street"
                                            :error="errors.collect('business_info_address').length > 0"
                                        />
                                    </v-col>
                                </v-row> 
                                <v-row>
                                    <v-col class="py-0">
                                        <HbTextField
                                            v-model="form.equipment.address2"
                                            v-validate.disable="{ customAddressValidation: true }"
                                            placeholder="Suite / Apt..."
                                            data-vv-name="business_info_address2"
                                            data-vv-as="Suite / Apt"
                                            :error="errors.collect('business_info_address2').length > 0"
                                        />
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col class="py-0">
                                        <HbTextField
                                            v-model="form.equipment.zip"
                                            v-validate.disable="'required|max:5'"
                                            placeholder="Zip"
                                            data-vv-name="business_info_zip"
                                            v-mask="'#####'"
                                            data-vv-as="Zip"
                                            :error="errors.collect('business_info_zip').length > 0"
                                        />
                                    </v-col>
                                    <v-col class="py-0">

                                        <vuetify-statepicker
                                            id="state"
                                            name="business_info_state"
                                            :single_line="true"
                                            data-vv-as="State"
                                            v-validate.disable="'required'"
                                            v-model="form.equipment.state"
                                            :clearable="true"
                                            @click:clear="form.state = ''"
                                            :error="errors.collect('business_info_state').length > 0"
                                        ></vuetify-statepicker>
                                    </v-col>

                                    <v-col class="py-0">
                                        <HbTextField
                                            v-model="form.equipment.city"
                                            v-validate.disable="'required'"
                                            placeholder="City"
                                            data-vv-name="business_info_city"
                                            data-vv-as="City"
                                            :error="errors.collect('business_info_city').length > 0"
                                        />
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col class="py-0"> 
                                        <HbAutocomplete
                                            v-model="form.equipment.country"
                                            :items="['USA','Canada']"
                                            v-validate.disable="'required'"
                                            data-vv-name="business_info_country"
                                            data-vv-as="Country"
                                            :error="errors.collect('business_info_country').length > 0"
                                            placeholder="Country"
                                        />
                                    </v-col>
                                </v-row>
                            </div>
                        </hb-form> 

                        <hb-form label="Billing Information" required multiple >
                            
                                <HbTextField
                                    v-model="form.equipment.name_on_card"
                                    v-validate.disable="'required'"
                                    placeholder="Name On Card"
                                    data-vv-name="equipment_name_on_card"
                                    data-vv-as="Name On Card"
                                    :error="errors.collect('equipment_name_on_card').length > 0"
                                />

                                <HbTextField
                                    v-model="form.equipment.card_number"
                                    v-validate.disable="'required|credit_card'"
                                    placeholder="Card Number"
                                    data-vv-name="card_number"
                                    data-vv-as="Card Number"
                                    v-mask="'####-####-####-####'"
                                    :error="errors.collect('equipment_card_number').length > 0"
                                />

                                <v-row>
                                    <v-col class="py-0"> 
                                        <HbTextField
                                            v-model="form.equipment.exp_date"
                                            v-validate.disable="'required|length:5'" 
                                            placeholder="MM/YY"
                                            v-mask="'##/##'"
                                            data-vv-name="equipment_exp_date"
                                            data-vv-as="Card Expiration Date"
                                            :error="errors.collect('equipment_exp_date').length > 0"
                                        />
                                    </v-col>
                                    <v-col class="py-0"> 
                                        <HbTextField
                                            v-model="form.equipment.cvv"
                                            v-validate.disable="'required|length:3,4'"
                                            placeholder="CVV"
                                            data-vv-name="equipment_cvv"
                                            data-vv-as="CVV"
                                            :error="errors.collect('equipment_cvv').length > 0"
                                        />
                                    </v-col>
                                    <v-col class="py-0"> 
                                        <HbTextField
                                            v-model="form.equipment.card_zip"
                                            v-validate.disable="'required|length:4,7'" 
                                            placeholder="Zip"
                                            data-vv-name="equipment_card_zip"
                                            data-vv-as="Card Zip"
                                            :error="errors.collect('equipment_card_zip').length > 0"
                                        /> 
                                    </v-col>
                                </v-row>
                            
                        </hb-form>

                       </div>
                    </template>
                </hb-expansion-panel>
               

                <!-- END Equipment -->
                <!-- Terms and Conditions -->



                <hb-expansion-panel class="mb-2">
                    <template v-slot:title>
                       Terms &amp; Conditions
                    </template>

                    <template v-slot:description>
                        Copy to inform users that they need to agree to both terms and conditions below.
                    </template>
                    <template v-slot:content>
                        <v-container>
                            <v-row>
                                <v-col>
                                    <HbCheckbox 
                                        v-model="form.terms.terms"  
                                        v-validate.disable="'required'"
                                        data-vv-name="terms"
                                        data-vv-as="Terms and Conditions"
                                        :error="errors.collect('terms').length > 0"
                                    >
                                        <template v-slot:label>
                                            <div>I agree to allow Tenant Inc. to use the information provided to process my application for a merchant account. I also accept the <strong><hb-link target="_blank" href="https://www.propay.com/en-US/Legal/ProFac-Sub-merchant-Terms-and-Conditions">Propay</hb-link></strong> and <strong><hb-link target="_blank" href="https://www.propay.com/en-US/Legal/Heartland-Propay-TC">Heartland-Propay</hb-link></strong> Terms &amp; Conditions.</div>
                                            
                                        </template>
                                    </HbCheckbox>
                                </v-col>
                            </v-row>

                            
                        </v-container>
                
                    </template>
                </hb-expansion-panel>
             </v-expansion-panels>
            <v-row>
                <v-col>
                    <img width="146px" src="/img/payment_logos/propay_logo.jpg" />
                </v-col>
            </v-row>
        </div>
    </template>
    <template v-slot:right-actions>
        <loader v-show="formLoading" color="#00b2ce" size="20px" class="inline-loader"></loader>
        <hb-btn :disabled="formLoading" @click="apply">Apply</hb-btn>
        
    </template> 
</hb-modal>
</div>
</template>


<script type="text/babel">
    import { Validator } from 'vee-validate';
    import { EventBus } from '../../../../EventBus.js';
    import VuetifyStatepicker from '../../../includes/VuetifyStatepicker.vue';
    import api from '../../../../assets/api.js';
    import { mapGetters } from 'vuex';
    import moment from 'moment';
    import Loader from '../../../assets/CircleSpinner.vue';
    import { notificationMixin } from  '../../../../mixins/notificationMixin.js';

    Validator.extend('emailAlphaNumeric', {
        getMessage: field => 'The ' + field + ' field can only contain alpha-numeric characters.',
        validate: (value) => {
            let namePart = value.split('@')[0];
            let regex = /[^0-9a-zA-Z]/;
            return !regex.test(namePart)
        }
    });
    

     export default {
         name: "ApplicationForm",
         mixins: [ notificationMixin ],
          data() {
            return {
                formLoading: false,
                dictionary: {
                    custom: {
                        account_owner_ssn:{
                            length: () => 'The SSN must have 9 digits'
                        },
                        beneficial_0_ssn:{
                            length: () => 'The Beneficial Owner SSN must have 9 digits'
                        }, 
                        beneficial_1_ssn:{
                            length: () => 'The Beneficial Owner SSN must have 9 digits'
                        }, 
                        beneficial_2_ssn:{
                            length: () => 'The Beneficial Owner SSN must have 9 digits'
                        }, 
                        beneficial_3_ssn:{
                            length: () => 'The Beneficial Owner SSN must have 9 digits'
                        }, 
                        beneficial_4_ssn:{
                            length: () => 'The Beneficial Owner SSN must have 9 digits'
                        }, 

                        business_info_ein:{
                            length: () => 'The EIN must have 9 digits'
                        }, 
                        equipment_exp_date:{
                            length: () => 'The Card Expiration Date must have 4 digits'
                        }
                    }
                },
               showForm: true,
               beneficiariesPanel: [0],
               devices: [{
                    label: 'Tenant A35',
                    value: 'Tenant A35'
                }],
                ownershipTypes: ['Personal', 'Business'],
                accountTypes: ['Checking', 'Savings'],
                countryCodes: ['USA', 'CA', 'AU'], 
                timeZones: {
                    HST: "Hawaii Standard Time (UTC-10)", 
                    AKST: "Alaska (Alaskan Standard Time) (UTC-9)", 
                    PT: "Pacific Time (US) (UTC-8/7DST)", 
                    MST: "Arizona (Mountain Standard Time[US]) (UTC-7)", 
                    MT: "Mountain Time (US) (UTC-7/6DST)", 
                    CT: "Central Time (US) (UTC-6/5DST)", 
                    ET: "Eastern Time (US) (UTC-5/4DST)", 
                    AT: "Atlantic Time (UTC-4/3DST)", 
                    AST: "Puerto Rico (Atlantic Standard Time)(UTC-4)", 
                    ECT: "European Central Time (UTC-1)", 
                    EET: "Eastern European Time (UTC-2)", 
                    EAT: "Eastern African Time (UTC-3)", 
                    MET: "Middle East Time (UTC-3.30)", 
                    NET: "Near East Time (UTC-4)", 
                    PLT: "Pakistan Lahore Time (UTC-5)", 
                    IST: "India Standard Time (UTC-5.30)", 
                    BST: "Bangladesh Standard Time (UTC-6)", 
                    VST: "Vietnam Standard Time (UTC-7)", 
                    CTT: "China Taiwan Time (UTC-8)", 
                    JST: "Japan Standard time (UTC-9)", 
                    ACT: "Australia Central Time (UTC-9.30)", 
                    AET: "Australia Eastern Time (UTC-10)", 
                    SST: "Solomon Standard Time (UTC-11)", 
                    NST: "New Zealand Standard Time (UTC-12)", 
                    MIT: "Midway Islands Time (UTC-11)", 
                    CNT: "Canada Newfoundland Time (UTC-3.30)", 
                    AGT: "Argentina Standard Time (UTC-3.00)", 
                    CAT: "Central African Time (UTC-1)",
                    UTC: "Universal Time Coordinated"
                    
                },
                form: {
                    general: {
                        registration_code: '',
                       
                    },
                    account_owner:{
                        first_name: '',
                        last_name: '',
                        ssn:'',
                        dob: '',
                        address:'',
                        address2: '',
                        zip: '',
                        state: '',
                        city:'',
                        email_address: '',
                        country:'',
                        day_phone_number: '',
                        evening_phone_number: '',
                        day_phone_country_code: '+1',
                        evening_phone_country_code: '+1'
                    },
                    business_information:{
                        legal_name:'',
                        dba: '',
                        ein:'',
                        address: '',
                        address2: '',
                        zip: '',
                        state: '',
                        city: '',
                        country: '',
                        notification_email: '',
                        website: '',
                        property_timezone: ''
                    },
                    beneficiaries:[
                        {
                            first_name: '',
                            last_name: '',
                            ssn:'',
                            dob: '',
                            address:'',
                            address2: '',
                            zip: '',
                            state: '',
                            city:'',
                            email_address: '',
                            country:'',
                            ownership:'',
                            title: ''
                        }
                    ],
                    equipment:{
                        will_purchase_equipment: false,
                        device_name: 'Tenant A35',
                        shipping_location_type: 'business',
                        address:'',
                        address2: '',
                        zip: '',
                        state: '',
                        city:'',
                        country:'',
                        name_on_card: '',
                        card_number: '',
                        exp_date: '',
                        cvv: '',
                        card_zip: ''
                    }, 
                    transaction_info: {
                        monthly_volume:'',
                        average_ticket: '',
                        highest_ticket: ''
                    },
                    banking_info:{
                        country_code: 'USA',
                        first_name: '',
                        last_name: '',
                        ownership_type: '',
                        account_type: '',
                        bank_name: '',
                        routing_number: '',
                        account_number: ''
                    },
                    terms:{
                        terms: false
                    }
                },
                form_panels: [0,1,2,3,4,5,6]
            }
          },
          components:{
              VuetifyStatepicker,
              Loader
          },
          created(){
            Validator.localize('en', this.dictionary);
          }, 
          props: ['property_id'],
          computed: {
               ...mapGetters({
                phoneCountryCodes: 'utilsStore/getPhoneCountryCodes',
            }),
            formattedTimeZones(){
                let tz = []
                for(let abbr in this.timeZones){
                    tz.push({
                        value: abbr,
                        label: this.timeZones[abbr]
                    })
                }
                return tz;
            },
          },
          methods:{

            addBeneficialOwner(){
                this.form.beneficiaries.push({
                    first_name: '',
                    last_name: '',
                    ssn:'',
                    dob: '',
                    address:'',
                    address2: '',
                    zip: '',
                    state: '',
                    city:'',
                    email_address: '',
                    country:'',
                    ownership:'',
                    title: ''
                })
            }, 
            removeBeneficialOwner(index){
                this.form.beneficiaries.splice(index, 1)
            },
            async apply(){
                if(this.formLoading) return;
                this.formLoading = true;

                
                if(this.form.equipment.shipping_location_type === 'business'){
                    this.form.equipment.address = this.form.business_information.address,
                    this.form.equipment.address2 = this.form.business_information.address2,
                    this.form.equipment.zip = this.form.business_information.zip,
                    this.form.equipment.state = this.form.business_information.state,
                    this.form.equipment.city = this.form.business_information.city,
                    this.form.equipment.country = this.form.business_information.country
                }
                let form = JSON.parse(JSON.stringify(this.form));

                
                let status = await this.$validator.validate();
            
                if(!status){
                    this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.',list: this.errors.items });
                    this.formLoading = false;
                    return; 
                }

                // Manually handle checks for terms and conditions until HbCheckbox supports validation
                if(!form.terms.terms){
                    this.showMessageNotification({ description: 'Please accept the terms and conditions.' })
                    this.formLoading = false;
                    return;
                }        

                
                try {
                    // preformat some data 
                    form.account_owner.day_phone = form.account_owner.day_phone_country_code + form.account_owner.day_phone_number;
                    form.account_owner.day_phone =  form.account_owner.day_phone.replace(/[^0-9]+/g, '')

                    form.account_owner.evening_phone = form.account_owner.evening_phone_country_code + form.account_owner.evening_phone_number;
                    form.account_owner.evening_phone =  form.account_owner.evening_phone.replace(/[^0-9]+/g, '')
                
                    let dob = moment(form.account_owner.dob, 'MM/DD/YYYY');
                   
                    if(!dob.isValid() || dob.valueOf() > moment().valueOf()){
                        this.showMessageNotification({ description: 'Please provide a valid Account Owner DOB.' })
                        this.formLoading = false;
                        return;
                    }

                    form.account_owner.dob = dob.format('YYYY-MM-DD');
                    
                    try {
                        form.beneficiaries = form.beneficiaries.map(b => {
                            console.log("b.dob", b.dob);
                            if(!b.dob) return b;
                            console.log("b.dob", b.dob);
                            let bdob = moment(b.dob, 'MM/DD/YYYY');
                            if(!bdob.isValid() || bdob.valueOf() > moment().valueOf()){
                                this.showMessageNotification({ description: 'Please provide a valid Beneficial Owner DOB.' })
                                throw "Invalid DOB"
                            }

                            b.dob = bdob.format('YYYY-MM-DD');
                            return b;
                         });
                    } catch(err){
                        this.formLoading = false;
                        return;
                    }
                    
                    
                     form.property_id = this.property_id;
                
                    // send application request to server
                    let r = await api.post(this, api.TENANT_PAYMENTS_APPLICATIONS, form); 
                        
                    var message = {};
                    if(r.status == 'approved'){
                        message = {
                            type: 'success',
                            description: "Congratulations. You have been approved to use Tenant Payments for your transactions."
                        }
                    } else {
                        message = {
                            type: 'caution',
                            description: "Please contact Onboarding or Customer Success to complete your application."
                        }
                    }

                    this.showMessageNotification(message);
                    this.$emit('submitted');
                } catch(err){
                    this.showMessageNotification({ description: err.toString() });
                }
                this.formLoading = false;
            }
        },
          
          watch: {
             
          }
     }
</script>