<template>
<div>
     <hb-form label="Merchant ID" required>
      <HbTextField
        :disabled="!!form.id"
        v-model="form.value.mid"
        v-validate.disable="'required'"
        data-vv-name="mid"
        data-vv-as="Merchant ID"
        :error="errors.collect('mid').length > 0"
      />
    </hb-form> 

     <hb-form label="Device ID" required>
      <HbTextField
        :disabled="!!form.id"
        v-model="form.value.deviceId"
        v-validate.disable="'required'"
        data-vv-name="deviceId"
        data-vv-as="Device ID"
        :error="errors.collect('deviceId').length > 0"
      />
    </hb-form> 

      <hb-form label="User ID" required>
        <HbTextField
          :disabled="!!form.id && !isEditing"
          v-model="form.value.userID"
          data-vv-name="userID"
          data-vv-as="User ID"
          :error="errors.collect('userID').length > 0"
        />
      </hb-form> 
      
      <hb-form label="Password" required>
        <HbTextField
          :disabled="!!form.id && !isEditing"
          v-model="form.value.password"
          data-vv-name="password"
          data-vv-as="Password"
          :error="errors.collect('password').length > 0"
        />
      </hb-form>  


      <div style="display: flex" >
          <v-col cols="8" class="hb-default-font-size font-weight-medium">Swiper Terminals</v-col>
          <v-col cols="4" class="align-right">
            <a v-if="!form.id || isEditing" @click="addDevice()">+ Add New Device</a> 
            
            </v-col>
      </div>

      <v-divider></v-divider>
      <v-expansion-panels flat v-model="devicePanel" multiple class="hb-expansion-panel ma-0 pa-0">

        
        <v-expansion-panel class="ma-0 pa-0" v-for="(d, i) in form.Devices" :key="i">
          <v-expansion-panel-header class="hb-table-label py-0 my-0 px-4 hb-subpanel-header">
              <v-col cols="8" class="pa-0 ma-0">
                  <span class="hb-default-font-size font-weight-medium">Device {{i+1}}</span>
              </v-col>
               <template v-slot:actions >
                  <div class="d-flex align-center mr-3">
                      <hb-link v-if="!form.id || isEditing" @click="removeDevice(i)" class="hb-default-font-size">Remove</hb-link>
                      <!-- <hb-link small v-else @click="testDevice(i)" class="hb-default-font-size">Test Device Connection</hb-link> -->
                      
                  </div>
                  <div class="d-flex align-center">
                    <v-icon>$expand</v-icon>
                  </div>
              </template> 
          </v-expansion-panel-header>
          <v-divider></v-divider>
          <v-expansion-panel-content class="pa-0 ma-0" elevation="0">
            <hb-form label="Device Name">
              <HbTextField
                :disabled="!!form.id && !isEditing"
                v-model="form.Devices[i].name"
                data-vv-name="device_name"
                data-vv-as="Device Name"
                :error="errors.collect('device_name').length > 0"
              />
            </hb-form> 


            <hb-form label="Device IP">
              <HbTextField
                :disabled="!!form.id && !isEditing"
                v-model="form.Devices[i].ip"
                data-vv-name="ip"
                data-vv-as="Device IP"
                :error="errors.collect('ip').length > 0"
              />
            </hb-form> 



            <hb-form label="Device Port">
                <HbTextField
                  :disabled="!!form.id && !isEditing"
                  v-model="form.Devices[i].port"
                  data-vv-name="device_port"
                  data-vv-as="Device Port"
                  :error="errors.collect('device_port').length > 0"
                />
              </hb-form> 
          </v-expansion-panel-content> 
        </v-expansion-panel>
      </v-expansion-panels>
 
 

      <hb-bottom-action-bar :cancel-off="!isEditing" @close="cancel" v-if="hasPermission('manage_payment_gateways')" :top-border="false">
      <template :cancel-off="(!!form.id && !isEditing) || !isEditAble" v-slot:right-actions > 
          <hb-btn :disabled="!hasPermission('delete_payment_gateway')" @click="remove" color="destructive" v-if="form.id && !isEditing && isEditAble">Remove Integration</hb-btn>
          <hb-btn @click="edit" v-if="form.id && !isEditing && isEditAble">Edit</hb-btn>
          <hb-btn @click="save" v-if="(!form.id || isEditing) && isEditAble">Save</hb-btn>
      </template>
    </hb-bottom-action-bar>
  </div> 
</template>

<script>
  import BaseSettings from '../../BasePaymentSettingsForm.vue'
  export default {
    name: 'TsysCard',
    extends: BaseSettings, 
    data() {
      return {
        devicePanel: [],
        defaultValues:{
          
          mid: '',
          deviceId: '',
          userID: '',
          password: ''
        } 
      }
    },
    created(){
      console.log("HERE", this.form.Devices)
    }
  
  }
</script>
