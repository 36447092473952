<template>
  <div>

    <hb-form label="Authorize.net Login" required>
      <HbTextField
        :disabled="!!form.id && !isEditing"
        v-model="form.value.authnetLogin"
        v-validate.disable="'required'"
        data-vv-name="authnetLogin"
        data-vv-as="Authorize.net Login"
        :error="errors.collect('authnetLogin').length > 0"
      />
    </hb-form> 



    <hb-form label="Authorize.net Key" required>
      <HbTextField
        :disabled="!!form.id && !isEditing"
        v-model="form.value.authnetKey"
        v-validate.disable="'required'"
        data-vv-name="authnetKey"
        data-vv-as="Authorize.net Key"
        :error="errors.collect('authnetKey').length > 0"
      />
    </hb-form> 

    <hb-bottom-action-bar :cancel-off="(!!form.id && !isEditing) || !isEditAble" @close="cancel" v-if="hasPermission('manage_payment_gateways')" :top-border="false">
      <template v-slot:right-actions > 
          <hb-btn :disabled="!hasPermission('delete_payment_gateway')" @click="remove" color="destructive" v-if="form.id && !isEditing && isEditAble">Remove Integration</hb-btn>
          <!-- <hb-btn text @click="cancel" v-if="(!form.id || isEditing) && isEditAble">Cancel</hb-btn> -->
          <hb-btn @click="save" v-if="(!form.id || isEditing) && isEditAble">Save</hb-btn>
      </template>
    </hb-bottom-action-bar>
    </div>
</template>

<script>
  import BaseSettings from '../../BasePaymentSettingsForm.vue'  
  export default {
    name: 'AuthorizenetSettings',
    extends: BaseSettings,
    data() {
      return {
        defaultValues: {
          authnetLogin: '',
          authnetKey: '',
          google: false,
          googleGatewayMerchantId: '',
          googleMerchantName: '',
          apple: false,
          appleMerchantId: '',
          appleMerchantName: ''
        }
      }
    }
  }
</script>
