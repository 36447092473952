<template>
  <div class="section-content">
    <div class="primary-section-content" v-if="property_id">
      <div class="header-class">
        <hb-header :divider="false" full>

        </hb-header>
        <hb-btn icon tooltip="Sidebar" :class="{
          'mr-3': $vuetify.breakpoint.mdAndDown && !$vuetify.breakpoint.xs,
        }" @click="showAside = !showAside" :active-by-default="true"
          :active-state-off="!showAside">mdi-table-actions-custom-1</hb-btn>
      </div>
      <v-divider></v-divider>
      <div class="main-wraper-container">

        <div class="section-content pt-4" :class="{ 'mt-10': $vuetify.breakpoint.xs, 'mt-n3': $vuetify.breakpoint.sm }">
          <div class="iframe-container" v-if="tenantPaymentStatus === 'opted'">
            <iframe v-if="iframeUrl" :src="iframeUrl" width=100% height=800px frameborder="0"></iframe>
            <div v-else class="pa-6">
              <hb-empty-state message="Please select a report from side-menu">
              </hb-empty-state>
            </div>
          </div>
          <div v-else class="pa-6">
            <hb-empty-state message="Advance reporting is only available for Tenant Payments"></hb-empty-state>
          </div>
        </div>


        <v-navigation-drawer v-model="showAside" :right="!$vuetify.breakpoint.xs"
          :hide-overlay="$vuetify.breakpoint.smAndUp" :clipped="!$vuetify.breakpoint.xs"
          :class="{ 'attached-drawer': !$vuetify.breakpoint.xs }" disable-resize-watcher mobile-breakpoint="600"
          class="ma-0 pa-0 py-1 hb-tertiary-navigation" :width="showAside ? '250px' : '0px'"
          :bottom="$vuetify.breakpoint.xs" :app="$vuetify.breakpoint.xs" flat>
          <v-list dense style="width: 100%">
            <v-list-item-group v-model="selection" >

              <hb-tooltip class="ml-1">
                <template v-slot:item>
                  <v-list-item class="pl-5" :disabled="tenantPaymentStatus !== 'opted'"
                    @click="loadProPayWidget('advanced-search')">
                    Advanced Transaction Search Report
                  </v-list-item>
                </template>
                <template v-slot:body>
                  <div style="width: 250px">Provides searching for a specific transaction and uses it to generate a report</div>
                </template>
              </hb-tooltip>
              <!-- <hb-tooltip>
                <template v-slot:item>
                  <v-list-item class="pl-5" :disabled="tenantPaymentStatus !== 'opted'"
                    @click="loadProPayWidget('transaction-details')">
                    Transaction Details
                  </v-list-item>
                </template>
                <template v-slot:body>
                  <div style="width: 250px">Allows merchants to generate a report showing key details for searched/selected transactions</div>
                </template>
              </hb-tooltip> -->
              <hb-tooltip>
                <template v-slot:item>
                  <v-list-item class="pl-5" :disabled="tenantPaymentStatus !== 'opted'"
                    @click="loadProPayWidget('transaction-report')">
                    Transaction Report
                  </v-list-item>
                </template>
                <template v-slot:body>
                  <div style="width: 250px">Allows account holders to view the transactions on their account - both pending and completed</div>
                </template>
              </hb-tooltip>
              <hb-tooltip>
                <template v-slot:item>
                  <v-list-item class="pl-5" :disabled="tenantPaymentStatus !== 'opted'"
                    @click="loadProPayWidget('sweep-report')">
                    Sweep Report
                  </v-list-item>
                </template>
                <template v-slot:body>
                  <div style="width: 250px">Shows account holders the different sweeps performed on their account, according to date range</div>
                </template>
              </hb-tooltip>
              <hb-tooltip>
                <template v-slot:item>
                  <v-list-item class="pl-5" :disabled="tenantPaymentStatus !== 'opted'"
                    @click="loadProPayWidget('risk-chargeback')">
                    Risk/List Chargeback
                  </v-list-item>
                </template>
                <template v-slot:body>
                  <div style="width: 250px">Lists all the retrieval requests and chargebacks for the merchant's account</div>
                </template>
              </hb-tooltip>

            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex/dist/vuex.common.js';
import { notificationMixin } from '../../../mixins/notificationMixin.js';
import api from '../../../assets/api.js';

export default {
  name: 'ProcessedPaymentsViewer',
  props: ['property_id'],
  data() {
    return {
      iframeUrl: '',
      selection: '',
      showAside: true,
    };
  },
  computed: {
    ...mapGetters({
      application: "tenantPaymentsApplicationStore/getApplication"
    }),
    tenantPaymentStatus() {
      const status = this.application?.status;
      if (status === 'approved' || status === 'ready_to_process') {
        return 'opted';
      } else {
        return 'not_opted';
      }
    },
  },
  watch: {
    property_id(newValue) {
      if (newValue) {
        this.iframeUrl = '';
        this.selection = '';
      }
    }

  },
  methods: {
    async loadProPayWidget(widgetType) {


      if (this.tenantPaymentStatus !== 'opted') {
        return;
      }

      try {

        let accountNumber = this.application?.account_number;
        const resp = await api.get(this, api.TENANT_PAYMENTS_APPLICATIONS + this.property_id + `/getSSOToken`, { "widgetType": widgetType, "accountNumber": accountNumber });
        const authToken = resp.token.AuthToken;


        const baseUrl = process.env.PROPAY_SSO_BASE_URL || 'https://propay.merchant-portals.com';
        let pageUrl = '';

        switch (widgetType) {
          case 'advanced-search':
            pageUrl = '/Report/AdvancedTransactionSearch';
            break;
          case 'transaction-details':
            pageUrl = '/Report/TransactionDetails';
            break;
          case 'transaction-report':
            pageUrl = '/Report/TransactionReport';
            break;
          case 'sweep-report':
            pageUrl = '/Report/SweepReport';
            break;
          case 'risk-chargeback':
            pageUrl = '/Risk/ListChargeBacks';
            break;
          default:
            pageUrl = '';
        }
        this.iframeUrl = `${baseUrl}${pageUrl}?authToken=${authToken}&accountnum=${accountNumber}`;
        console.log("iframeUrl: ", this.iframeUrl)

      } catch (error) {
        console.log('error:', error)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.section-content {
  .header-class {
    display: flex;
    justify-content: space-between;
  }

  .iframe-container {
    padding: 10px;
    background-color: #F9FAFB;
  }

  .main-wraper-container {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 73px);
    overflow: hidden;
  }

}
</style>
