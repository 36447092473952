<template>
    <div>
        
        <hb-modal
            size="large"
            title="Update Tenant Payments Information"
            v-model="showForm"  
            @close="$emit('close')" 
            show-help-link
        >
        <template v-slot:subheader>
            Enter the bank account your payment processing proceeds will be
            deposited into.
        </template>
        <template v-slot:content >
            <div class="pa-4" style="background: #F9FAFB; height: 502.5px; overflow: auto ">
    
                <v-expansion-panels v-model="form_panels" multiple>
                    <hb-expansion-panel class="mb-2">
                        <template v-slot:title>
                           Banking Information
                        </template>
                        <template v-slot:content>
                            
                            <hb-form label="Name on Account" required multiple>
                                <v-row >
                                    <v-col class="py-0"> 
                                        <HbTextField
                                            v-model="form.banking_information.bank_name_on_account"
                                            v-validate.disable="'required'"
                                            placeholder="Name on Account"
                                            data-vv-name="bank_name_on_account"
                                            data-vv-as="Name on Account"
                                            :error="errors.collect('bank_name_on_account').length > 0"
                                        />
                                    </v-col>
                                </v-row>
                            </hb-form> 
    
    
                            <hb-form label="Account Ownership Type" required >
                                <HbSelect
                                    v-model="form.banking_information.bank_ownership_type"
                                    :items="ownershipTypes"
                                    item-text="label"
                                    item-value="value"
                                    v-validate.disable="'required'"
                                    data-vv-name="ownership_type"
                                    data-vv-as="Account Ownership Type"
                                    :error="errors.collect('ownership_type').length > 0"
                                    placeholder="Select"
                                    :clearable="false"
                                />  
                            </hb-form>
    
    
                            <hb-form label="Account Type" required >
                                <HbSelect
                                    v-model="form.banking_information.bank_account_type"
                                    :items="accountTypes"
                                    item-text="label"
                                    item-value="value"
                                    v-validate.disable="'required'"
                                    data-vv-name="account_type"
                                    data-vv-as="Account Type"
                                    :error="errors.collect('account_type').length > 0"
                                    placeholder="Select"
                                    :clearable="false"
                                />  
                            </hb-form>
    
                            <hb-form label="Bank Name" required >
                                <HbTextField
                                    v-model="form.banking_information.bank_name"
                                    v-validate.disable="'required'"
                                    placeholder="Bank Name"
                                    data-vv-name="bank_name"
                                    data-vv-as="Bank Name"
                                    :error="errors.collect('bank_name').length > 0"
                                />
                            </hb-form>
    
                            <hb-form label="Bank Routing Number" required >
                                <HbTextField
                                    v-model="form.banking_information.bank_routing_num"
                                    @input="trimRoutingInput"
                                    v-validate.disable="'required|confirmed:confirmRoutingNum'"
                                    placeholder="Routing Number"
                                    data-vv-name="routing_number"
                                    data-vv-as="Routing Number"
                                    :error="errors.collect('routing_number').length > 0"
                                />
                            </hb-form>

                            <hb-form label="Confirm Bank Routing Number" required>
                                <HbTextField
                                    v-model="confirmRoutingNumber"
                                    @input="trimConfirmRoutingInput"
                                    ref="confirmRoutingNum"
                                    v-validate.disable="'required'"
                                    placeholder="Re-Enter Routing Number"
                                    data-vv-name="confirm_routing_number"
                                    data-vv-as="Routing Number Confirmation"
                                    data-vv-scope="banking_info"
                                    :error="errors.collect('confirm_routing_number').length > 0" 
                                    @paste="disablePaste"
                                    />
                                <div v-if="routingNumbersDoNotMatch" class="error-message">
                                    Routing numbers do not match.
                                </div>
                            </hb-form>

                            <hb-form label="Bank Account Number" required >
                                <HbTextField
                                    v-model="form.banking_information.bank_account_num"
                                    @input="trimAccountInput"
                                    v-validate.disable="'required|confirmed:confirmAccountNum'"
                                    placeholder="Account Number"
                                    data-vv-name="account_number"
                                    data-vv-as="Account Number"
                                    :error="errors.collect('account_number').length > 0"
                                />
                            </hb-form>

                            <hb-form label="Confirm Bank Account Number" required>
                                <HbTextField
                                    v-model="confirmAccountNumber"
                                    @input="trimConfirmAccountInput"
                                    ref="confirmAccountNum"
                                    v-validate.disable="'required'"
                                    placeholder="Re-Enter Account Number"
                                    data-vv-name="confirm_account_number"
                                    data-vv-as="Account Number Confirmation"
                                    :error="errors.collect('confirm_account_number').length > 0"
                                    @paste="disablePaste"
                                />
                                <div v-if="accountNumbersDoNotMatch" class="error-message">
                                    Account numbers do not match.
                                </div>
                            </hb-form>
    
                            <hb-form label="Country Code" required >
                                <HbSelect
                                    v-model="form.banking_information.country_code"
                                    :items="countryCodes"
                                    v-validate.disable="'required'"
                                    data-vv-name="country_code"
                                    data-vv-as="Country Code"
                                    :error="errors.collect('country_code').length > 0"
                                    placeholder="Select"
                                    :clearable="false"
                                />  
                            </hb-form>
    
                        </template>
                    </hb-expansion-panel>
                </v-expansion-panels>
            </div>
        </template>
    
        <template v-slot:right-actions>
            <hb-btn :disabled="loading" :loading="loading" @click="save">Update Information</hb-btn>
        </template> 
    
        </hb-modal>
    </div>
    </template>            
    
    <script type="text/babel">
    import { EventBus } from '../../../../EventBus.js';
    import api from '../../../../assets/api.js';
    import { mapGetters, mapActions } from 'vuex';
    import { notificationMixin } from "../../../../mixins/notificationMixin.js";
    export default {
        name: "UpdateForm",
        mixins: [notificationMixin],
        data() {
            return {
                showForm: true,
                form_panels: [0],
                countryCodes: ['USA'],
                ownershipTypes: [
                    {
                    label: "Personal",
                    value: "personal",
                    },
                    {
                    label: "Business",
                    value: "business",
                    },
                ],
                accountTypes: [
                    {
                    label: "Checking",
                    value: "checking",
                    },
                    {
                    label: "Savings",
                    value: "savings",
                    },
                ],
                loading: false,
                form: {
                    banking_information: {
                        bank_name_on_account: "",
                        bank_ownership_type: "",
                        bank_account_type: "",
                        bank_name: "",
                        bank_routing_num: "",
                        bank_account_num: "",
                        country_code: "",
                    },
                },
                    confirmRoutingNumber: '',
                    confirmAccountNumber: '',
                    routingNumbersDoNotMatch: false,
                    accountNumbersDoNotMatch: false,
            }
        },
        watch: {
            'form.banking_information.bank_routing_num'(newVal) {
            this.checkRoutingNumbersMatch();
            },
            confirmRoutingNumber(newVal) {
            this.checkRoutingNumbersMatch(); 
            },
            'form.banking_information.bank_account_num'(newVal) {
            this.checkAccountNumbersMatch();
            },
            confirmAccountNumber(newVal) {
            this.checkAccountNumbersMatch();
            },
        },
        computed: {
            ...mapGetters({
                getApplication: "tenantPaymentsApplicationStore/getApplication",
                getPropertyID: "tenantPaymentsApplicationStore/getPropertyID",
            }),
        },
        mounted() {
            this.setFormData();
        },
        methods: {
            ...mapActions({
                updateTenantPaymentsApplication:
                    "tenantPaymentsApplicationStore/updateTenantPaymentsApplication",
            }),
            setFormData() {
                if (this.getApplication) {
                    this.form.banking_information.bank_ownership_type = this.getApplication.bank_ownership_type;
                    this.form.banking_information.bank_account_type = this.getApplication.bank_account_type;
                    this.form.banking_information.bank_name = this.getApplication.bank_name;
                    this.form.banking_information.bank_routing_num = this.getApplication.bank_routing_num;
                    this.form.banking_information.country_code = this.getApplication.bank_country_code;
                    this.form.banking_information.bank_name_on_account = this.getApplication.bank_name_on_account;
                }
            },
            async save() {
                try {
                    const status = await this.$validator.validateAll();
                    if(!status){
                        return;
                    }
                    this.loading = true;
                    const formData = {
                        banking_info: {
                            ownership_type: this.form.banking_information.bank_ownership_type,
                            account_type: this.form.banking_information.bank_account_type,
                            bank_name: this.form.banking_information.bank_name,
                            routing_number: this.form.banking_information.bank_routing_num,
                            account_number: this.form.banking_information.bank_account_num,
                            country_code: this.form.banking_information.country_code,
                            name_on_account: this.form.banking_information.bank_name_on_account,
                        },
                        is_submit: true,
                    }
                    await this.updateTenantPaymentsApplication({ form: formData });
                    this.showMessageNotification({ type: 'success', description: 'You have successfully updated your banking information.' });
                    this.showForm = false;
                    this.loading = false;
                    this.$emit('update');
                } catch (err) {
                    this.showMessageNotification({ description: err.toString() });
                    this.loading = false;
                }
            },
            disablePaste(event) {
            event.preventDefault();
            },
            checkRoutingNumbersMatch() {
            if (this.form.banking_information.bank_routing_num !== this.confirmRoutingNumber) {
                this.routingNumbersDoNotMatch = true;
            } else {
                this.routingNumbersDoNotMatch = false;
            }
            },
            checkAccountNumbersMatch() {
            if (this.form.banking_information.bank_account_num !== this.confirmAccountNumber) {
                this.accountNumbersDoNotMatch = true;
            } else {
                this.accountNumbersDoNotMatch = false;
            }
            },
            trimRoutingInput(){
                this.form.banking_information.bank_routing_num = this.form.banking_information.bank_routing_num.trim();
            },
            trimConfirmRoutingInput(){
                this.confirmRoutingNumber = this.confirmRoutingNumber.trim();
            },
            trimAccountInput(){
                this.form.banking_information.bank_account_num = this.form.banking_information.bank_account_num.trim();
            },
            trimConfirmAccountInput(){
                this.confirmAccountNumber = this.confirmAccountNumber.trim();
            },
            
        },
    }
</script>
    
    <style lang="scss" scoped>
    .error-message {
      color: red;
      font-size: 14px;
      margin-top: 5px;
    }
    </style>