<template>
  <div>

    <hb-form label="User Name" required>
      <HbTextField
        :disabled="!!form.id && !isEditing"
        v-model="form.value.userName"
        v-validate.disable="'required'"
        data-vv-name="userName"
        data-vv-as="User Name"
        :error="errors.collect('userName').length > 0"
      />
    </hb-form> 

    <hb-form label="Token Password" required>
      <HbTextField
        :disabled="!!form.id && !isEditing"
        v-model="form.value.tokenPassword"
        v-validate.disable="'required'"
        data-vv-name="tokenPassword"
        data-vv-as="Token Password"
        :error="errors.collect('tokenPassword').length > 0"
      />
    </hb-form> 

    <hb-bottom-action-bar :cancel-off="(!!form.id && !isEditing) || !isEditAble" @close="cancel" v-if="hasPermission('manage_payment_gateways')" :top-border="false">
      <template v-slot:right-actions > 
          <hb-btn :disabled="!hasPermission('delete_payment_gateway')" @click="remove" color="destructive" v-if="form.id && !isEditing && isEditAble">Remove Integration</hb-btn>
          <!-- <hb-btn text @click="cancel" v-if="(!form.id || isEditing) && isEditAble">Cancel</hb-btn> -->
          <hb-btn @click="save" v-if="(!form.id || isEditing) && isEditAble">Save</hb-btn>
      </template>
    </hb-bottom-action-bar>
    </div>
</template>

<script>
  import BaseSettings from '../../BasePaymentSettingsForm.vue'  
  export default {
    name: 'FatzebraCardSettings',
    extends: BaseSettings,
    data() {
      return {
        defaultValues: {
          userName: '',
          tokenPassword: ''
        }
      }
    }
  }
</script>
