<template>
  <div>



    <hb-form label="Merchant ID" required>
      <HbTextField
        :disabled="!!form.id"
        v-model="form.value.mid"
        v-validate.disable="'required'"
        data-vv-name="mid"
        data-vv-as="Merchant ID"
        :error="errors.collect('mid').length > 0"
      />
    </hb-form> 

    <hb-form label="Device ID" required>
      <HbTextField
        :disabled="!!form.id"
        v-model="form.value.deviceId"
        v-validate.disable="'required'"
        data-vv-name="deviceId"
        data-vv-as="Device ID"
        :error="errors.collect('deviceId').length > 0"
      />
    </hb-form> 

    <hb-form label="User ID" required>
      <HbTextField
        :disabled="!!form.id && !isEditing"
        v-model="form.value.userID"
        v-validate.disable="'required'"
        data-vv-name="userID"
        data-vv-as="User ID"
        :error="errors.collect('userID').length > 0"
      />
    </hb-form> 
    
    <hb-form label="Password" required>
      <HbTextField
        :disabled="!!form.id && !isEditing"
        v-model="form.value.password"
        v-validate.disable="'required'"
        data-vv-name="password"
        data-vv-as="Password"
        :error="errors.collect('password').length > 0"
      />
    </hb-form> 
    <hb-bottom-action-bar :cancel-off="(!!form.id && !isEditing) || !isEditAble" @close="cancel"  v-if="hasPermission('manage_payment_gateways')">
      <template v-slot:right-actions > 
          <hb-btn :disabled="!hasPermission('delete_payment_gateway')" @click="remove" color="destructive" v-if="form.id && !isEditing && isEditAble">Remove Integration</hb-btn>
          <hb-btn @click="edit" v-if="form.id && !isEditing && isEditAble">Edit</hb-btn>
          <hb-btn @click="save" v-if="(!form.id || isEditing) && isEditAble">Save</hb-btn>
      </template>
    </hb-bottom-action-bar>
  </div>
</template>

<script>
  import BaseSettings from '../../BasePaymentSettingsForm.vue'
  export default {
    name: 'AchTsys',
    extends: BaseSettings, 
    data() {
      return {

        defaultValues: {
        
          mid: '',
          deviceId: '',
          userID: '',
          password: ''
        }        
      }
    }
  }
</script>