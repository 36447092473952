<template>
    <div>

        <v-expansion-panels v-model="panel" >
            <hb-expansion-panel class="mb-2" guidance :leftCols="'4'" :rightCols="'8'">
                <template v-slot:title>
                    Tenant Payments
                </template>
                <template v-slot:actions>
                    <hb-btn v-if="!getApplication" class="my-0 mr-4" @click.native.stop="loadApplicationDrawer"
                        small>Apply Now</hb-btn>
                    <hb-btn v-else-if="resumeApplication" class="my-0 mr-4" @click.native.stop="loadApplicationDrawer"
                        small>Resume
                        Application</hb-btn>
                    <div v-else-if="hasApplied">
                        <div class="d-flex px-4">
                            <div class="d-flex flex-wrap align-center">
                                <hb-status-general :type="applicationStatusColor" :status="formatStatus(getApplication.status)"></hb-status-general>
                            </div>
                            <hb-btn v-if="hasPermission('update_payout_account')" class="mx-3" @click.native.stop="loadUpdateForm" small>Update Banking Information</hb-btn>
                            <hb-btn class="mx-3" @click.native.stop="loadOrderForm" small>Purchase Equipment</hb-btn>

                        </div>
                    </div>

                    
                </template>
                <template v-slot:content class="pa-10">
                    <div class="pa-6" v-if="!hasApplied">
                        This is our preferred payment solution. It offers all the functionality you need for processing credit cards and ACH payments, coupled with the top-notch customer support that you’d expect from Tenant Inc. Apply now to create your account and begin using Tenant Payments.
                    </div>
                    <account-data v-else></account-data>

                </template>
            </hb-expansion-panel>     
        </v-expansion-panels>  
        
        <update-form v-if="showUpdateForm" @close="showUpdateForm = false" :application="application" @update="formSubmitted"> </update-form>
        <order-form v-if="showOrderForm" @close="showOrderForm = false" @update="formSubmitted"> </order-form>
        <application-form v-if="showApplicationForm" @close="closeForm" @submitted="formSubmitted" :property_id="property_id"></application-form>
   
    </div>
</template>

<script type="text/babel">
// import HbExpansionPanel from '../../../../aviary/HbExpansionPanel.vue';
import AccountData from './AccountData.vue'; 
import moment from 'moment';
import ApplicationForm from './ApplicationForm.vue';
import UpdateForm from './UpdateForm.vue';
import OrderForm from './OrderForm.vue';
import { mapGetters } from 'vuex';
import { EventBus } from '../../../../EventBus';
    export default {

        name: 'PaymentConfiguration',
        data() {
            return {
                panel: 0,
                showApplicationForm: false,
                showUpdateForm: false,
                showOrderForm: false
            }
        },
        mounted() {
                this.$validator.extend('customAddressValidation', {
                validate: value => {
                    const regex = /(?!(.*([pP][\.\s]*[oO0][\.\s]*[bB]\s*[oO0]\s*[xX]|[bB]\s*[oO0]\s*[xX][\s#]*[0-9])))^.+$/;
                    return regex.test(value);
                },
                getMessage: field => 'The address cannot contain PO Box.',

                }); 
            },
        props: ['property_id', 'application'],
        
        components:{
            AccountData,
            ApplicationForm,
            UpdateForm,
            OrderForm
        },
        computed: {
            ...mapGetters({
                hasPermission: 'authenticationStore/rolePermission',
                getApplication: 'tenantPaymentsApplicationStore/getApplication',
            }),
            resumeApplication(){
                return this.getApplication && !this.getApplication.account_number
            },
            hasApplied(){
                return this.getApplication && this.getApplication.account_number
            },
            applicationStatusColor(){
                switch(this.getApplication.status){
                    case 'partial_approval':
                    case 'fraud_account':
                    case 'check_pending':
                    case 'additional_information':
                    case 'pending_unpaid':
                    case 'hold':
                        return 'caution';
                    case 'risk_wise_declined':
                    case 'closed_e_u_l_a':
                    case 'canceled':
                    case 'closed_collections':
                        return 'error';
                    case 'ready_to_process':
                    case 'approved':
                        return 'success';
                }
            },
        },
        filters:{
            
        },
        methods:{
           formatStatus(status){
                return status.split('_').map(s => this.$options.filters.capitalize(s)).join(' ');   
            },
            loadApplicationDrawer(){
                EventBus.$emit('HB-Navigation:TenantPaymentsApplication');
            },
            loadUpdateForm(){
                this.showUpdateForm = true;
            },
            loadOrderForm(){
                this.showOrderForm = true;
            },
            formSubmitted(){
                this.$emit('submitted');
                this.closeForm();
            }, 
            closeForm(){
                this.showApplicationForm = false
                this.showUpdateForm = false
                this.showOrderForm = false
            }
            
        }
    }

</script>

