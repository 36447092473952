<template>
  <v-container fluid>
    <v-row>
      <v-col
        >Your Tenant Payments account has been created. Your account details are
        found below.</v-col
      >
    </v-row>
    <v-row>
      <v-col>
        <p>
          <span class="hb-text-night-light">Account Number:</span><br />
          {{ getApplication.account_number }}
        </p>
        <p>
          <span class="hb-text-night-light">Username:</span><br />
          {{ getApplication.source_email }}
        </p>

        <!-- <p>
            <span class="hb-text-night-light">Password:</span><br/>
            {{getApplication.password | hashPassword}}
        </p> -->

        <p>
          <span class="hb-text-night-light">API Key:</span><br />
          {{ getApplication.api_key }}
        </p>
      </v-col>
      <v-col>
        <p>
          <span class="hb-text-night-light">Site ID:</span><br />
          {{ getApplication.site_id }}
        </p>

        <p>
          <span class="hb-text-night-light">Device ID:</span><br />
          {{ getApplication.propay_device_id }}
        </p>

        <p>
          <span class="hb-text-night-light">Account Type:</span><br />
          {{ getApplication.account_type }}
        </p>
      </v-col>
      <v-col>
        <p>
          <span class="hb-text-night-light">Bank Account:</span><br />
          {{ getApplication.bank_account_num | hashNumber }}
        </p>
        <p>
          <span class="hb-text-night-light">Bank Routing Number:</span><br />
          {{ getApplication.bank_routing_num | hashNumber }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script type="text/babel">
import { mapGetters } from "vuex";
export default {
  name: "Accountdata",
  data() {
    return {
      tiers: [],
    };
  },
  filters: {
    formatPercentLocal(amt) {
      return Math.round(amt * 1e4) / 1e2 + "%";
    },
    hashNumber(num) {
      if(num){
      return Buffer.alloc(num.length - 4, "x").toString() + num.slice(-4);
      }
      return ' '
    },
    hashPassword(num) {
      return (
        num.slice(0, 2) +
        Buffer.alloc(num.length - 4, "x").toString() +
        num.slice(-2)
      );
    },
  },
  computed: {
    ...mapGetters({
      getApplication: "tenantPaymentsApplicationStore/getApplication",
    }),
  },
};
</script>
