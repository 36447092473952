<template>
    <div>  
        <hb-modal
            size="large"
            title="Purchasing Equipment"
            v-model="showForm"  
            @close="$emit('close')" 
            show-help-link
        >
        <template v-slot:subheader>        
            Fill out the information below to complete your terminal equipment purchase for Tenant Payments.    
        </template>
        <template v-slot:content >
            <div class="pa-4" style="background: #F9FAFB; height: 502.5px; overflow: auto ">
    
                <!--  Business Information -->
    
                <v-expansion-panels v-model="form_panels" multiple>
                    <!-- Equipment -->
    
                    <hb-expansion-panel class="mb-2">
                        <template v-slot:title>
                            Purchase Equipment
                        </template>
    
                        <template v-slot:content>
    
                             <hb-form label="Device Name" required >
                                <HbSelect
                                    :items="devices" 
                                    v-model="form.equipment.device_name"
                                    v-validate.disable="'required'"
                                    item-text="label"
                                    item-value="value"
                                    :clearable="false"
                                    data-vv-name="device_name"
                                    data-vv-as="Device Name"
                                    placeholder="Device Name"
                                    :error="errors.collect('device_name').length > 0"
                                />
                            </hb-form> 
                             <hb-form label="Shipping Address" required>
                               <v-row>
                                 <v-col class="py-0">
                                   <HbTextField v-model="form.equipment.contact_name"
                                                v-validate.disable="'required|length:1,30'"
                                                placeholder="Contact Name"
                                                data-vv-name="business_info_contact_name"
                                                data-vv-as="Name"
                                                :error="errors.collect('business_info_contact_name').length > 0" />
                                 </v-col>
                               </v-row>
                               <v-row>
                                 <v-col class="py-0">
                                   <HbTextField v-model="form.equipment.address"
                                                v-validate.disable="{ required: true, customAddressValidation: true }"
                                                placeholder="Street"
                                                data-vv-name="business_info_address"
                                                data-vv-as="Street"
                                                :error="errors.collect('business_info_address').length > 0" />
                                 </v-col>
                               </v-row>
                               <v-row>
                                 <v-col class="py-0">
                                   <HbTextField v-model="form.equipment.address2"
                                                v-validate.disable="{ customAddressValidation: true }"
                                                placeholder="Suite / Apt..."
                                                data-vv-name="business_info_address2"
                                                data-vv-as="Suite / Apt"
                                                :error="errors.collect('business_info_address2').length > 0" />
                                 </v-col>
                               </v-row>
    
                               <v-row>
                                 <v-col class="py-0">
                                   <HbTextField v-model="form.equipment.zip"
                                                v-validate.disable="'required|max:5'"
                                                placeholder="Zip"
                                                data-vv-name="business_info_zip"
                                                v-mask="'#####'"
                                                data-vv-as="Zip"
                                                :error="errors.collect('business_info_zip').length > 0" />
                                 </v-col>
                                 <v-col class="py-0">
    
                                   <vuetify-statepicker id="state"
                                                        name="business_info_state"
                                                        :single_line="true"
                                                        data-vv-as="State"
                                                        v-validate.disable="'required'"
                                                        v-model="form.equipment.state"
                                                        :clearable="true"
                                                        @click:clear="form.state = ''"
                                                        :error="errors.collect('business_info_state').length > 0"></vuetify-statepicker>
                                 </v-col>
    
                                 <v-col class="py-0">
                                   <HbTextField v-model="form.equipment.city"
                                                v-validate.disable="'required'"
                                                placeholder="City"
                                                data-vv-name="business_info_city"
                                                data-vv-as="City"
                                                :error="errors.collect('business_info_city').length > 0" />
                                 </v-col>
                               </v-row>
    
                               <v-row>
                                 <v-col class="py-0">
                                   <HbAutocomplete v-model="form.equipment.country"
                                                   :items="['USA','Canada']"
                                                   v-validate.disable="'required'"
                                                   data-vv-name="business_info_country"
                                                   data-vv-as="Country"
                                                   :error="errors.collect('business_info_country').length > 0"
                                                   placeholder="Country" />
                                 </v-col>
                               </v-row>
                               <v-row>
                                 <v-col class="py-0">
                                   <HbTextField v-model="form.equipment.phone"
                                                v-validate.disable="'required|length:12'"
                                                v-mask="'###-###-####'"
                                                placeholder="Phone"
                                                data-vv-name="business_info_phone"
                                                data-vv-as="Phone"
                                                :error="errors.collect('business_info_phone').length > 0" />
                                 </v-col>
                               </v-row>
                               <!--/div-->
                             </hb-form> 
    
                            <hb-form label="Billing Information" required multiple >
                                
                                    <HbTextField
                                        v-model="form.equipment.name_on_card"
                                        v-validate.disable="'required'"
                                        placeholder="Name On Card"
                                        data-vv-name="equipment_name_on_card"
                                        data-vv-as="Name On Card"
                                        :error="errors.collect('equipment_name_on_card').length > 0"
                                    />
    
                                    <HbTextField
                                        v-model="form.equipment.card_number"
                                        v-validate.disable="'required|credit_card'"
                                        placeholder="Card Number"
                                        data-vv-name="card_number"
                                        data-vv-as="Card Number"
                                        v-mask="'####-####-####-####'"
                                        :error="errors.collect('equipment_card_number').length > 0"
                                    />
    
                                    <v-row>
                                        <v-col class="py-0"> 
                                            <HbTextField
                                                v-model="form.equipment.exp_date"
                                                v-validate.disable="'required|length:5'" 
                                                placeholder="MM/YY"
                                                v-mask="'##/##'"
                                                data-vv-name="equipment_exp_date"
                                                data-vv-as="Card Expiration Date"
                                                :error="errors.collect('equipment_exp_date').length > 0"
                                            />
                                        </v-col>
                                        <v-col class="py-0"> 
                                            <HbTextField
                                                v-model="form.equipment.cvv"
                                                v-validate.disable="'required|length:3,4'"
                                                placeholder="CVV"
                                                data-vv-name="equipment_cvv"
                                                data-vv-as="CVV"
                                                :error="errors.collect('equipment_cvv').length > 0"
                                            />
                                        </v-col>
                                        <v-col class="py-0"> 
                                            <HbTextField
                                                v-model="form.equipment.card_zip"
                                                v-validate.disable="'required|length:4,7'" 
                                                placeholder="Zip"
                                                data-vv-name="equipment_card_zip"
                                                data-vv-as="Card Zip"
                                                :error="errors.collect('equipment_card_zip').length > 0"
                                            /> 
                                        </v-col>
                                    </v-row>
                                
                            </hb-form>
                        </template>
                    </hb-expansion-panel>            
                    <!-- END Equipment -->
    
    
                    <!-- END Banking Information -->
                </v-expansion-panels>
            </div>
        </template>
    
        <template v-slot:right-actions>
            <hb-btn v-for="btn in buttons" :key="btn.id" :disabled="btn.disabled" @click="save(btn)">Purchase Equipment</hb-btn>
        </template> 
    
        </hb-modal>
    </div>
    </template>            
    
    <script type="text/babel">
      import { EventBus } from '../../../../EventBus.js';
      import api from '../../../../assets/api.js';
      import { mapGetters } from 'vuex';
      import VuetifyStatepicker from '../../../includes/VuetifyStatepicker.vue';
	  import { notificationMixin } from  '../../../../mixins/notificationMixin.js';
       export default {
           name: "OrderForm",
		   mixins: [ notificationMixin ],
            data() {
              return {
                 showForm: true,
                  form_panels: [0],
                  devices: [{
                      label: 'Tenant A35',
                      value: 'Tenant A35'
                  }],
                  buttons: [
                        { id: 1, disabled: false },
                  ],
                  form:{
                      equipment:{
                              device_name: 'Tenant A35',
                              shipping_location_type: 'business',
                              contact_name:'',
                              address:'',
                              address2: '',
                              zip: '',
                              state: '',
                              city:'',
                              country:'',
                              phone:'',
                              name_on_card: '',
                              card_number: '',
                              exp_date: '',
                              cvv: '',
                              card_zip: ''
                          },
                  }
              }
          },
            computed: {
                ...mapGetters({
                    getApplication: "tenantPaymentsApplicationStore/getApplication",
                }),
            },
          methods:{
    
              async save(button){
                  try {
                    button.disabled = true;
                    const status = await this.$validator.validateAll();
                    if(!status){
                        button.disabled = false;
                        return;
                    }
                      let r = await api.put(this, api.TENANT_PAYMENTS_EQUIPMENT + this.getApplication.id, this.form);
    
                      var message = {};
                        if(r.status_code == '0'){
							this.showMessageNotification({ type: 'success', description: "You have successfully placed a request to purchase an equipment." });
                            this.showForm = false;
                        } else {
                            button.disabled = false;
							this.showMessageNotification({ type: 'error', description: "Status : "+ r.status_code +" - " + r.status});
                        }
                        
                        this.$emit('submitted');
                  } catch(err){
                      this.showMessageNotification({ description: err });
                  }
              }
          },
          components:{
              VuetifyStatepicker
          },
      }
    </script> 
